
import { defineComponent, onMounted, ref } from "vue";
import CursoColumn from "@/util/table-columns/CursoColumn";
import CursoForm from "@/components/admin/cursos/CursoForm.vue";
import ImagemUpload from "@/components/admin/ImagemUpload.vue";
import useList from "@/modules/useList";
import store from "@/store";
import QBtn from 'quasar/src/components/btn/QBtn.js';import QIcon from 'quasar/src/components/icon/QIcon.js';import QInput from 'quasar/src/components/input/QInput.js';import QSpace from 'quasar/src/components/space/QSpace.js';import QTable from 'quasar/src/components/table/QTable.js';import QTd from 'quasar/src/components/table/QTd.js';import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';import useQuasar from 'quasar/src/composables/use-quasar.js';;
import Disciplina from "@/interfaces/Disciplina";
import DisciplinaService from "@/services/admin/DisciplinaService";
import DivisaoService from "@/services/auth/DivisaoService";
import BaseService from "@/services/admin/BaseService";

export default defineComponent({
  components: { CursoForm, ImagemUpload },
  setup() {
    const $q = useQuasar();
    const disciplinas = ref<Disciplina[]>([]);
    const {
      url,
      urlImg,
      rows,
      loading,
      filter,
      columns,
      list,
      formatData,
      modalForm,
      openModalForm,
      modalUpload,
      openModalUpload,
      confirmDelete,
    } = useList(
      store.getters.divisaoId
        ? "divisoes/cursos/" + store.getters.divisaoId
        : "cursos",
      CursoColumn
    );

    const getDisciplinas = (): void => {
      loading.value = true;
      if (store.getters.divisaoId) {
        DivisaoService.getDisciplinasSelect(store.getters.divisaoId)
          .then((res) => {
            disciplinas.value = res.data;
          })
          .catch((err) => {
            console.error(err.response.data.message);
            showNotify({
              type: "negative",
              message: err.response.data.message,
            });
          })
          .finally(() => (loading.value = false));
      } else {
        BaseService.list("disciplinas/list-select")
          .then((res) => {
            disciplinas.value = res.data;
          })
          .catch((err) => {
            console.error(err.response.data.message);
            showNotify({
              type: "negative",
              message: err.response.data.message,
            });
          })
          .finally(() => (loading.value = false));
      }
    };
  const showNotify = (opts: any): void => {
      $q.notify(opts);
    };

    onMounted(() => {
      getDisciplinas();
    });

    return {
      modalForm,
      openModalForm,
      modalUpload,
      openModalUpload,
      url,
      columns,
      rows,
      loading,
      filter,
      formatData,
      list,
      urlImg,
      disciplinas,
      getDisciplinas,
      confirmDelete,
    };
  },
});
