import { onMounted, ref } from "vue";
import BaseService from "@/services/admin/BaseService";
import Column from "@/interfaces/Column";
import Dialog from 'quasar/src/plugins/Dialog.js';import Notify from 'quasar/src/plugins/Notify.js';;

export default (endpoint: string, column: any) => {
  const url = ref<string>(process.env.VUE_APP_API_BASE);
  const urlImg = ref<string>(process.env.VUE_APP_STORAGE_BASE);
  const rows = ref([]);
  const filter = ref<string>("");
  const loading = ref<boolean>(false);
  const modalForm = ref();
  const modalEdit = ref();
  const modalUpload = ref();
  const columns = ref<Column[]>(column);

  const openModalForm = (row: any): void => {
    modalForm.value.open(row);
  };

  const openModalEdit = (row: any): void => {
    modalEdit.value.open(row);
  };

  const openModalUpload = (id: string): void => {
    modalUpload.value.open(id);
  };

  const list = (optionalEndpoint?: string): void => {
    loading.value = true;
    BaseService.list(optionalEndpoint ? optionalEndpoint : endpoint)
      .then((res) => {
        rows.value = res.data;
      })
      .catch((err) => {
        console.error(err.response.data.message);
        Notify.create({
          type: "negative",
          message: err.response.data.message,
        });
      })
      .finally(() => (loading.value = false));
  };

  const confirmDelete = (id: string, nome: string): void => {
    Dialog.create({
      title: `Excluir ${nome}`,
      message: `Deseja realmente excluir esse registro?`,
      ok: {
        icon: "delete",
        color: "red",
        label: "EXCLUIR",
      },
      cancel: {
        icon: "close",
        color: "green",
        label: "CANCELAR",
      },
      persistent: true,
    }).onOk(() => destroy(id));
  };

  const destroy = (id: string): void => {
    loading.value = true;
    BaseService.delete(endpoint, id)
      .then(() => {
        list();
        Notify.create({
          type: "positive",
          message: `Registro excluído com sucesso`,
        });
      })
      .catch((err) => {
        console.error(err.response.data.message);
        Notify.create({
          type: "negative",
          message: err.response.data.message,
        });
      })
      .finally(() => (loading.value = false));
  };

  const formatData = (data: string): string => {
    return `${data.substr(8, 2)}/${data.substr(5, 2)}/${data.substr(0, 4)}`;
  };

  onMounted(() => {
    list();
  });

  return {
    // Data
    url,
    urlImg,
    rows,
    filter,
    loading,
    columns,
    modalForm,
    modalEdit,
    modalUpload,

    // Methods
    list,
    destroy,
    formatData,
    confirmDelete,
    openModalForm,
    openModalEdit,
    openModalUpload,
  };
};
