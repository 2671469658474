
import { ref, defineComponent } from "vue";
import useQuasar from 'quasar/src/composables/use-quasar.js';;

export default defineComponent({
  props: {
    url: { default: "" },
  },
  setup(props, { emit }) {
    const id = ref<string>();
    const $q = useQuasar();
    const show = ref<boolean>(false);
    const token = ref<string>();

    const open = (cursoId: string): void => {
      if (localStorage.getItem("token")) {
        id.value = cursoId;
        token.value = "Bearer " + localStorage.getItem("token")?.toString();
        show.value = true;
      }
    };

    const onRejected = (rejectedEntries: any): void => {
      $q.notify({
        type: "negative",
        message: `${rejectedEntries.length} file(s) did not pass validation constraints`,
      });
    };

    const afterSubmit = (): void => {
      emit("update");
      show.value = false;
      showNotify({
        type: "positive",
        message: "Imagem salva com sucesso",
      });
    };

    const afterFailed = (): void => {
      showNotify({
        type: "negative",
        message: "Erro ao salvar imagem",
      });
    };

    const showNotify = (opts: any): void => {
      $q.notify(opts);
    };

    return {
      afterSubmit,
      afterFailed,
      onRejected,
      show,
      open,
      id,
      token,
      showNotify,
    };
  },
});
