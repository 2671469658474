
import { defineComponent, onMounted, ref } from "vue";
import { requiredField } from "@/util/form-rules";
import FormLoading from "../../layout/FormLoading.vue";
import Curso from "@/interfaces/Curso";
import Disciplina from "@/interfaces/Disciplina";
import useForm from "@/modules/useForm";
import store from "@/store";
import BaseService from "@/services/admin/BaseService";
import Divisao from "@/interfaces/Divisao";

interface Form {
  descricao: string;
  resumo: string | null;
  disciplinas: string[];
  divisao_id?: string;
}

const baseForm: Form = {
  descricao: "",
  resumo: "",
  disciplinas: [],
  divisao_id: ""
};
const divisoes = ref<Divisao[]>([]);

export default defineComponent({
  components: { FormLoading },
  props: { disciplinas: { type: Object, required: true } },
  setup(props, { emit }) {

    const { show, form, row, loading, submit, afterSubmit } = useForm(
      "cursos",
      "curso",
      "o",
      baseForm,
      emit,
      "cursos"
    );

    const open = (data: Curso | null) => {
      if (data) {
        row.value = JSON.parse(JSON.stringify(data));
        if (row.value?.id) {
          form.value = {
            descricao: row.value.descricao,
            resumo: row.value.resumo,
            disciplinas: row.value.disciplinas.map((d: Disciplina) => d.id),
            divisao_id: row.value.divisao.id,
          };
        } else {
          form.value = JSON.parse(JSON.stringify(baseForm));
          if (store.getters.divisaoId) {
            form.value.divisao_id = store.getters.divisaoId;
          }
        }
      }
      show.value = true;
    };

    const getDivisoes = (): void => {
      BaseService.list("divisoes")
        .then(({ data }) => {
          divisoes.value = data;
        })
        .catch((err) => {
          console.error(err.response.data.message);
        });
    };

    onMounted(() => {
      getDivisoes();
    });

    return {
      show,
      open,
      form,
      row,
      requiredField,
      loading,
      submit,
      afterSubmit,
      divisoes,
      store,
    };
  },
});
